<template>
  <div class="office">
    <div class="office_banner_wraper">
      <div class="office_banner_container">
        <div class="office_banner_container_title">智能办文解决方案</div>
        <div class="office_banner_container_desc">
          提供最适合区县级的一体化规范化办文系统
        </div>
        <div class="office_banner_container_btn">在线咨询</div>
      </div>
    </div>
    <div class="office_problem">
      <div class="office_problem_title">在工作中，你也许遇到这些问题</div>
      <div class="office_problem_wraper">
        <div class="office_problem_item_left">
          <div
            class="office_problem_item"
            v-for="(item, index) in left"
            :key="index"
          >
            <div class="office_problem_item_title">{{ item.title }}</div>
            <div class="office_problem_item_desc">{{ item.desc }}</div>
          </div>
        </div>
        <div class="office_problem_item_right">
          <div
            class="office_problem_item"
            v-for="(item, index) in right"
            :key="index"
          >
            <div class="office_problem_item_title">{{ item.title }}</div>
            <div class="office_problem_item_desc" v-html="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="office_system">
      <div class="office_system_title">智能办文，同一系统全流程高效办文</div>
      <div class="office_system_desc">
        各单位使用同一系统，线上统一实现来文呈批、发文办理、电子签批、文件归档等日常办公<br />
        全流程高效简便，网上办文代替人手办文，节约行政成本，提高行政效率，实现最适合区县级的办文系统
      </div>
      <div class="office_tab">
        <div class="office_tab_title">
          <div
            class="office_tab_title_item"
            :class="{ active: activeIndex === 1 }"
            @mouseenter="changeIndex(1)"
          >
            功能架构
          </div>
          <span></span>
          <div
            class="office_tab_title_item"
            :class="{ active: activeIndex === 2 }"
            @mouseenter="changeIndex(2)"
          >
            流程定制
          </div>
        </div>

        <div class="office_tab_change">
          <div class="office_tab_change_item" v-if="activeIndex === 1">
            <div class="office_tab_change_item_img">
              <img src="@/assets/images/solve/office/img1.png" alt="" />
            </div>
          </div>
          <div class="office_tab_change_item" v-if="activeIndex === 2">
            <div class="office_tab_change_item_img">
              <img src="@/assets/images/solve/office/img4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="office_wraper">
      <div class="office_swiper">
        <p>全流程智能办文系统</p>
        <div class="office_box">
          <div
            class="office_box_label"
            :class="{ select: state === index }"
            v-for="(item, index) in officeList"
            :key="index"
            @mouseover="select(item, index)"
          >
            <img :src="item.img" />
            <p :class="{ selectText: state === index }">{{ item.label }}</p>
          </div>
        </div>
        <div class="office_item">
          <p>{{ stateItem.title }}</p>
          <img :src="stateItem.img" />
        </div>
      </div>
    </div>

    <div class="office_success">
      <div class="office_success_title">成功案例，与你分享价值所在</div>
      <div class="office_success_desc">
        佛山市高明区全区所有机关单位，包括区五套班子、区直各部门、各镇（街道）机关等，一套智能办文系统全面覆盖
      </div>
      <div class="office_success_wraper">
        <div class="office_success_left">
          <div class="office_success_left_icon">
            <img src="@/assets/images/solve/office/ago.png" alt="" />
          </div>
          <p>以前</p>
          <div class="office_success_left_info">
            公文签批苦走流程，效率无法得以提高<br />
            给领导签批还要一等再等，打印机和碎纸机都很累
          </div>
          <div class="office_success_left_img">
            <img src="@/assets/images/solve/office/left.png" alt="" />
          </div>
        </div>
        <div class="office_success_right">
          <div class="office_success_left_icon">
            <img src="@/assets/images/solve/office/now.png" alt="" />
          </div>
          <p>现在</p>
          <div class="office_success_left_info">
            网上流转代替人手流转，大幅提高办文速度
            <br />
            再也不用跑腿送文件了，部门间沟通也高效多了
          </div>
          <div class="office_success_left_img">
            <img src="@/assets/images/solve/office/right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <solve-footer></solve-footer>
  </div>
</template>

<script>
import $ from 'jquery'
import SolveFooter from '@/components/SolveFooter'
export default {
  name: '',
  components: {
    SolveFooter
  },
  props: {

  },
  data () {
    return {
      activeIndex: 1,
      left: [
        {
          title: '系统操作难',
          desc: '现有的系统不能完全适配工作流程'
        }, {
          title: '文件整理复杂',
          desc: '各项文件都需人工归档，整理和查找都很耗时间'
        }, {
          title: '办文需留痕',
          desc: '办文过程中，文档的每处编辑都要留痕'
        }
      ],
      right: [
        {
          title: '领导签批久',
          desc: '领导需要在办公室内用纸笔签批，也不能实<br/>现协同签批，签批流程又长又慢'
        }, {
          title: '办文效率低',
          desc: '无法实时了解办文进度，公文处理不及时，<br/>公文盖章跑断腿'
        }
      ],
      state: 0,
      officeList: [
        {
          state: 0,
          img: require('@/assets/images/solve/office/icon0.png'),
          label: '适配你的办公流程'
        },
        {
          state: 1,
          img: require('@/assets/images/solve/office/icon1.png'),
          label: '提升文件办理效率'
        },
        {
          state: 2,
          img: require('@/assets/images/solve/office/icon2.png'),
          label: '文件编辑自动留痕'
        },
        {
          state: 3,
          img: require('@/assets/images/solve/office/icon3.png'),
          label: '领导签批快速灵活'
        },
        {
          state: 4,
          img: require('@/assets/images/solve/office/icon4.png'),
          label: '数字化档案管理'
        }
      ],
      stateItem:
      {
        img: require('@/assets/images/solve/office/fqsw0.png'),
        title: '根据您的工作流程，定制与之相匹配的的办文流程'
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    setTimeout(() => {
      $('.office_problem_item_left').addClass('fade')
      $('.office_problem_item_right').addClass('fade')
    }, 100)
    window.addEventListener('scroll', this.scroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    scroll () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.office_success_wraper').offset().top
      if (h > targetHeight - 500) {
        $('.office_success_left').addClass('animation')
        $('.office_success_right').addClass('animation')
      }
    },
    changeIndex (id) {
      this.activeIndex = id
    },
    select (item, index) {
      this.state = index
      this.officeList.forEach(element => {
        if (item.label === element.label) {
          element.img = require('@/assets/images/solve/office/icon' + +index + '_ok.png')
        } else {
          element.img = require('@/assets/images/solve/office/icon' + +element.state + '.png')
        }
      })
      var title = ['根据您的工作流程，定制与之相匹配的的办文流程', '不同单位、部门间公文快速交换，可对公文进行跟踪、督办等，支持在线套红', '办文过程中在线编辑留痕，每一环节都有记录', '支持多部门协同签批，真迹签名签批；就算领导出差，也可随时移动签批', '支持多部门协同签批，真迹签名签批；就算领导出差，也可随时移动签批']
      this.stateItem.img = require('@/assets/images/solve/office/fqsw' + +index + '.png')
      this.stateItem.title = title[index]
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
